import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Heading4 from '../../components/Type/Heading4'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="Porsche Macan buyers guide"
      description="Buying a Porsche Macan? Read more for Macan prices and our advice on buying the best car"
      location={location}
    />

    <PageHeader
      title="Porsche Macan buyers guide"
      text="After the huge hit that was its full-size SUV, the Cayenne, Porsche made no mistakes with the big car’s stablemate, the mid-sized Macan, introduced in 2014. "
    />

    <SectionPageMargin>

      <Heading2>Buying a Porsche Macan? Read more for Macan prices and our advice on buying the best car</Heading2>

      <Paragraph1>Last update: 30 November 2021</Paragraph1>

      <Heading4>Market Analysis</Heading4>

      <Paragraph1>
        Super-stylish and very well proportioned, the Macan truly lives up to the promise of the Porsche badge on its nose, with driving dynamics of such a high standard that it’s more sports car than SUV.
      </Paragraph1>

      <Paragraph1>
        With its suave looks, upmarket cabin, keen performance and sharp handling, the Macan has enjoyed great success in Australia and now accounts for about half of Porsche’s sales volume here. That popularity combined with new car supply issues – blame Covid-induced international shipping problems and the current global shortage of the microchips on which modern cars rely – means that in line with the rest of the secondhand market, prices of used Macans are robustly high.
      </Paragraph1>

      <Paragraph1>
        Even a 2014 example with 150,000km+ on the clock is likely to be on the upside of $50,000, which gives you an idea of how strong the used Macan market is. What we’re looking at here, though are Macans from 2017 through to 2020, and we’re sticking with the 2.0-litre turbocharged petrol base model, 3.0-litre turbocharged petrol V6 Macan S, and 3.0-litre turbodiesel Diesel S. Our experience shows these are the Macans most buyers are interested in, although two other models are available, the GTS and Turbo.
      </Paragraph1>


      <Paragraph1>
        Summon’s price estimates and valuations for the Macan are based upon data gleaned from private and dealer sales advertisements from the past 12 months. One immediate takeaways is the big jump in average prices for both petrol models between 2018 and 2019: up $15,000 for the base Macan, $23,000 for the Macan S. This can be attributed to the 2019 facelift for the car, which tweaked the styling front and rear, introduced LED lights, improved the engines and suspension, and updated the infotainment system.
      </Paragraph1>

      <Paragraph1>
        And talking of average price differentials, the Diesel S lags behind its petrol-engined S counterpart to the tune of about $4,600. Even so, a 2018 (the last year it was made) Diesel S with less than 40K on the clock will set you back between about $86,000 and $90,000, while a 2017 car that’s racked up 150,000km or more can still make $58,000.
      </Paragraph1>

      <Paragraph1>
        Mileage does have a bearing on used values for the Macan, but not a massive impact. A 2017 base model car with less than 20K on the odometer is roughly $82,000, yet if you triple the mileage you’ll still pay approximately $76,000. A Macan S from 2019 with less than 10,000km under its wheels is just over $116,000, falling back only to $104,000 with five times the mileage: a 2017 Macan S costs $84,500 for a sub-20,000km example, and drops only $5K for an 80,000km+ machine. There are larger gaps with the Diesel S, though – a 2018 car with less than 40K on the odometer is in the region of $86,000 to just the other side of $90,000, but at 50,000km+ prices fall back to about $76,700
      </Paragraph1>


      <Heading4>What is it?</Heading4>

      <Paragraph1>
        A premium mid-sized high performance SUV, the Porsche Macan is the class leader for driving dynamics and driver satisfaction. In the right hands, the turbocharged 3.0-litre Macan S can be punted along almost as quickly as some of Porsche’s two-seater models; that’s how performance-oriented this 4x4 is.
      </Paragraph1>

      <Paragraph1>
        That four-wheel drive system is an ‘on-demand’ setup, meaning that it operates on all four wheels only when there’s a loss of traction or you’re off-road. In regular driving the Macan works as a rear-drive car, from which it derives its sporting prowess. All Macans have a seven-speed sports automatic gearbox, a dual-clutch affair that Porsche calls PDK: it’s a top quality ’box featuring manual override through steering wheel-mounted paddleshifters.
      </Paragraph1>

      <Paragraph1>
        The three Macan derivatives we’re exploring here are the base model with a 2.0-litre four-cylinder turbocharger petrol engine producing 185kW and 370Nm of torque; the Macan S powered by a 3.0-litre turbocharged petrol V6 giving 250kW and 460Nm; and the 3.0-litre turbodiesel V6 Diesel S, good for 190kW and 580Nm. The Macan S is the sportiest of the trio, but even the base model can be whipped along at a decent pace. The Diesel S’s strength lies in its mighty mid-range performance, and on winding back roads it’s far quicker than you’d imagine.
      </Paragraph1>

      <Paragraph1>
        All Macans have a decent if not spectacular level of standard equipment – alloys, leather, climate control, powered seats, tailgate, windows and mirrors, automatic headlights and wipers, infotainment and premium audio system – but a car’s final specification was very much in the hands of the original purchaser choosing from a lengthy options list. It may therefore take you a while to find the exact specification you desire, so do your research about what’s available and maybe work out what you believe is essential and what would merely be nice to have.
      </Paragraph1>

      <Paragraph1>
        Worth keeping an eye out for, though, are cars fitted with Porsche Active Suspension Management, or PASM – this gives you clever adaptive dampers that enhance the sporting prowess of the suspension without damaging the ride quality. PASM works best on cars with steel suspension springs. The Sports Chrono pack is also fun to have, especially on the Macan S, as it optimises the overall performance of the engine, gearbox and steering, amongst other things, making the car quicker and more entertaining.
      </Paragraph1>


      <Heading4>What to look out for</Heading4>
      <Paragraph1>
        <strong>Engine:</strong> All three engines have generally proven reliable. Some petrol engines pre-2019 were subject to recalls for a possible faulty fuel pump and leaking fuel lines, so check remedial work has been done. Failing ignition coils can cause rough running on petrol engines, whilst diesels mainly used for city driving can have problems with the diesel particulate filter (DPF).

      </Paragraph1>

      <Paragraph1>
        <strong>Suspension:</strong> Though not a common fault, some Macans with optional air suspension have suffered air leaks. The outward sign of this is that the car no longer sits evenly on its suspension.
      </Paragraph1>

      <Paragraph1>
        <strong>Alloy wheels:</strong> Beware kerb rash on Macans used for the hurly-burly of the school run.
      </Paragraph1>

      <Paragraph1>
        <strong>Interior:</strong> he Macan is popular as a family car, so look out for scuffed seatbacks and broken bits of minor trim. Some owners have suffered issues with the Macan’s infotainment system, so make sure everything works – if it’s not covered by warranty, the unit is fearsomely expensive to replace.
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
